import React from "react";

const Info = () => {
  return (
    <div className="about__info grid">
      <div className="about__box">
        <i class="bx bxs-briefcase-alt about__icon"></i>

        <h3 className="about__title">Experience</h3>
        <span className="about__subtitle">4 internships</span>
      </div>
      <div className="about__box">
        <i class="bx bxs-award about__icon"></i>

        <h3 className="about__title">Research</h3>
        <span className="about__subtitle">1 publication</span>
      </div>
      <div className="about__box">
        <i class="bx bx-code-block about__icon"></i>

        <h3 className="about__title">Projects</h3>
        <span className="about__subtitle">14+ Projects</span>
      </div>
    </div>



  );
};

export default Info;
