import React, { useState } from "react";
import "./qualification.css";

const Qualification = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className="qualification section" id="qual">
      <h2 className="section__title">Education and Work Experience</h2>

      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div
            className={
              toggleState === 1
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(1)}
          >
            <i className="uil uil-graduation-cap qualification__icon"></i>
            Education
          </div>

          <div
            className={
              toggleState === 2
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(2)}
          >
            <i className="uil uil-briefcase-alt qualification__icon"></i>
            Experience
          </div>
        </div>

        <div className="qualification__sections">
          <div
            className={
              toggleState === 1
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >

            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Summer Business Scholar</h3>
                <span className="qualification__subtitle">
                  Booth School of Business - University of Chicago
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> 2021 - 2021
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">BS in Computer Science</h3>
                <span className="qualification__subtitle">
                  Virginia Tech
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> 2019 - Present
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              toggleState === 2
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">ML Research student</h3>
                <span className="qualification__subtitle">
                  Virginia Tech-Myers Lawson School of Construction
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> Sep 2023 - Present
                </div>
              </div>
            </div>
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">ML research internship</h3>
                <span className="qualification__subtitle">
                  VCU - Virginia Tech Department of Mathematics
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> May 2023 - Present
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">Undergraduate Research Assistant</h3>
                <span className="qualification__subtitle">
                  Virginia Tech-Department of Computer Science
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> Sep 2021 - Mar 2022
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Web development and Marketing intern</h3>
                <span className="qualification__subtitle">360 Marketing</span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> Mar 2021 - May 2021
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">Associate</h3>
                <span className="qualification__subtitle">
                  Consulting Group at Virginia Tech
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> Mar 2021 - Aug 2021
                </div>
              </div>
            </div>
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Founder and Tech Lead</h3>
                <span className="qualification__subtitle">Let It Out!</span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> Nov 2020 - Mar 2021
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">Student intern</h3>
                <span className="qualification__subtitle">
                  Krios Technologies
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt"></i> Mar 2018 - Apr 2018
                </div>

              </div>
            </div>



          </div>
        </div>
      </div>
    </section >
  );
};

export default Qualification;
