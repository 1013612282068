import Work1 from "../../assets/facemask.png";
import Work2 from "../../assets/smiledetect.png";
import Work3 from "../../assets/dogbreed.png";
import Work4 from "../../assets/heart.png";
import Work5 from "../../assets/tictactoe.jpg";
import Work6 from "../../assets/smart.jpg";
import Work7 from "../../assets/todolist.jpg";
import Work8 from "../../assets/ticks.jpg";
import Work9 from "../../assets/flight.png";
import Work10 from "../../assets/traffic.png";
import Work11 from "../../assets/prt.jpg";

export const projectsData = [
  {
    id: 1,
    image: Work1,
    title: "Face Mask Detection",
    category: "Machine Learning",
  },
  {
    id: 2,
    image: Work2,
    title: "Smile Detection",
    category: "Machine Learning",
  },
  {
    id: 3,
    image: Work3,
    title: "Dog Breed Classification Model",
    category: "Machine Learning",
  },
  {
    id: 4,
    image: Work4,
    title: "Heart Disease Prediction Model",
    category: "Machine Learning",
  },
  {
    id: 5,
    image: Work5,
    title: "Tic-Tac-Toe app",
    category: "Others",
  },
  {
    id: 6,
    image: Work6,
    title: "Smart brain Backend",
    category: "Web",
  },
  {
    id: 7,
    image: Work7,
    title: "To do list",
    category: "Web",
  },
  {
    id: 8,
    image: Work8,
    title: "Flight Ticket Price Prediction Model",
    category: "Machine Learning",
  },
  {
    id: 9,
    image: Work9,
    title: "Flight Ticket Price Prediction Website",
    category: "Web",
  },
  {
    id: 10,
    image: Work10,
    title: "Traffic Light Detection",
    category: "Machine Learning",
  },
  {
    id: 11,
    image: Work11,
    title: "Personal Portfolio",
    category: "web",
  },
];

// projects
export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "Machine Learning",
  },
  {
    name: "Web",
  },
  {
    name: "Others",
  },
];
