import React from "react";

const Other = () => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">Other skills</h3>

            <div className="skills__box">
                <div className="skills__group">
                    <div className="skills__data">
                        <i class="bx bx-code-alt"></i>

                        <div>
                            <h3 className="skills__name">Android app development</h3>
                            <span className="skills__level">Intermediate</span>
                        </div>
                    </div>
                    <div className="skills__data">
                        <i class="bx bxs-business"></i>

                        <div>
                            <h3 className="skills__name">Business consulting</h3>
                            <span className="skills__level">Intermediate</span>
                        </div>
                    </div>
                    <div className="skills__data">
                        <i class="bx bxs-business"></i>

                        <div>
                            <h3 className="skills__name">Product Marketing</h3>
                            <span className="skills__level">Intermediate</span>
                        </div>
                    </div>
                </div>
                <div className="skills__group">

                    <div className="skills__data">
                        <i class="bx bx-code-alt"></i>

                        <div>
                            <h3 className="skills__name">Web scraping</h3>
                            <span className="skills__level">Advanced</span>
                        </div>
                    </div>
                    <div className="skills__data">
                        <i class="bx bx-code-alt"></i>

                        <div>
                            <h3 className="skills__name">Testing</h3>
                            <span className="skills__level">Advanced</span>
                        </div>
                    </div>
                    <div className="skills__data">
                        <i class="bx bx-code-alt"></i>

                        <div>
                            <h3 className="skills__name">leadership</h3>
                            <span className="skills__level">Intermediate</span>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default Other;